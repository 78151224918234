import React from "react";
import { Link } from "react-router-dom";
import CSS from "csstype";
import { getAnalyticsAttrsFromProps } from "analytics";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { composeStyles, withZoomOnHover } from "utils/styles";
import classNames from "classnames";
import { ifSites1492 } from "utils/toggles";

const baseStyles = createStyles({});

const styles = createStyles({
  ...baseStyles,

  imageWithZoomOnHover: composeStyles(baseStyles, styles =>
    ifSites1492(withZoomOnHover(styles), styles)
  )
});

export type SmartCropProps = {
  [key: string]: any;
};

export function isObjectEmpty(obj: any) {
  return !obj || Object.keys(obj).length == 0;
}

export interface ImageComponentProps {
  classes?: WithStyles<typeof baseStyles | "imageWithZoomOnHover">["classes"];
  smartCrops: SmartCropProps;
  fileReference: string;
  imageLink?: string;
  openInNewWindow?: boolean;
  enableSmartImaging?: boolean;
  zoomOnHover?: boolean;
}

interface ImageComponentState {
  imageSrc: { src: string; srcset: string };
}

class Image extends React.Component<ImageComponentProps, ImageComponentState> {
  constructor(props: ImageComponentProps) {
    super(props);

    this.state = {
      imageSrc: this.imageUrl()
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateImage.bind(this));
  }

  componentDidUpdate() {
    const currentSrc = this.state.imageSrc;
    const newSrc = this.imageUrl();

    if (
      currentSrc?.src != newSrc?.src ||
      currentSrc?.srcset != newSrc?.srcset
    ) {
      this.updateImage();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateImage);
  }

  updateImage() {
    this.setState({
      imageSrc: this.imageUrl()
    });
  }

  imageUrl() {
    const imageProps = this.props;
    let src = imageProps.fileReference;
    let srcset;

    if (!isObjectEmpty(imageProps.smartCrops)) {
      srcset = Object.entries(imageProps.smartCrops)
        .map(([key, value]) => {
          try {
            const url = new URL(value);

            if (imageProps.enableSmartImaging) {
              url.searchParams.set("bfc", "on");
            }
            value = url.toString();
          } catch (error) {
            console.error("cant convert dm smart crop ", value);
          }
          return `${value} ${key === "1" ? "380" : key}w`;
        })
        .join(", ");
    }

    return { srcset, src };
  }

  get imageHTML() {
    const { classes, imageLink, openInNewWindow, zoomOnHover } = this.props;
    const { imageSrc } = this.state;
    const analyticsAttrs = getAnalyticsAttrsFromProps(this.props);

    const imgStyles: CSS.Properties = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto"
    };

    if (!imageLink)
      return (
        <img
          id={"component-rh-dm-smart-crop-image_img"}
          {...analyticsAttrs}
          className={classNames({
            [classes?.imageWithZoomOnHover]: zoomOnHover
          })}
          src={imageSrc.src}
          srcSet={imageSrc.srcset}
          style={imgStyles}
        />
      );

    const isExternalLink =
      imageLink?.startsWith("http://") || imageLink?.startsWith("https://");

    return isExternalLink ? (
      <a
        id={"component-rh-dm-smart-crop-image_a"}
        {...analyticsAttrs}
        href={imageLink}
        target={openInNewWindow ? "_blank" : ""}
      >
        <img
          className={classNames({
            [classes?.imageWithZoomOnHover]: zoomOnHover
          })}
          src={imageSrc.src}
          srcSet={imageSrc.srcset}
          style={imgStyles}
        />
      </a>
    ) : (
      imageLink && (
        <Link
          id={"component-rh-dm-smart-crop-image_link"}
          {...analyticsAttrs}
          to={imageLink}
          target={openInNewWindow ? "_blank" : ""}
        >
          <img
            className={classNames({
              [classes?.imageWithZoomOnHover]: zoomOnHover
            })}
            src={imageSrc.src}
            srcSet={imageSrc.srcset}
            style={imgStyles}
          />
        </Link>
      )
    );
  }

  render() {
    return this.imageHTML || <React.Fragment />;
  }
}

export default withStyles(styles)(Image);
